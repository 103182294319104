import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locI18next from "loc-i18next";

import logofr from '../img/logo-fr.svg';
import logonl from '../img/logo-nl.svg';


const localize = locI18next.init(i18next);

i18next.init({
    debug: true,
    lng: (window.location.href.indexOf('verlegmeegrenzen') > -1) ? 'nl' : 'fr',
    resources: {
      fr: {
        translation: {
            main: {
                skip: "Passer l’introduction",
                progress:"Faites défiler pour découvrir",
                siteTitle:"Médecins sans frontières - Prepaid Donations",
                switchLang:"NL",
            },
    
            logo:{
                alt: "Médecins sans frontières",
                link: "https://www.msf-azg.be/fr",
                source:"logo-fr",
            },
    
            titles: {
                firstTitle: "Repoussons la frontière<br/>entre abandonner...",
                secondTitle: "...et tout tenter.",
                thirdTitle: "Qui est MSF ?",
                fourthTitle: "Nos interventions",
                fifthTitle:"Repoussons la frontière<br>entre compassion<br>et action.",
                sixthTitle:"Repoussons la frontière<br> entre renoncer<br> et tout tenter.",
                seventhTitle:"Repoussons la frontière<br> entre arriver trop tard<br> et juste à temps.",  
                eighthTitle :"Prepaid<br> Donations.",
                lastTitle : "Choisissez<br> votre méthode <br> de soutien",
            },
            subtitles: {
                firstSubtitle: "Accès aux soins de santé",
                secondSubtitle: "Crises et conflits",
                thirdSubtitle: "Recherches et sauvetages"
                // fourthSubtitle: "Présentation Prepaid Donations"
            },
            cta: {
                mainCta :"Faites un don",
                mainCtaLink : "https://donate.msf-azg.be/b?cid=119&lang=fr_FR",
                smallCta:"READ MORE",
                smallCtaLink:"https://www.msf-azg.be/fr",
                smallCtaLinkPrepaid:"https://www.prepaiddonations.be/fr",
                firstCta :"Don classique",
                firstCtaLink :"https://donate.msf-azg.be/b?cid=119&lang=fr_FR",
                secondCta :"Don Prepaid Donations",
                secondCtaLink :"https://www.prepaiddonations.be/fr",
                smallCtaLinkthird :"https://www.msf-azg.be/fr/qui-sommesnous",
                smallCtaLinktfourth :"https://www.msf-azg.be/fr/nouvelles",
            },
    
            firstCase:{
                firstParagraph : "Les soins de santé n'ont pas de frontières. Pourtant, nous nous heurtons chaque jour à des frontières géographiques, économiques et politiques qui nous empêchent de fournir les meilleurs soins possibles. C'est pourquoi, Médecins Sans Frontières se bat pour repousser ces frontières, par tous les moyens possibles. Nous tentons de rester sur place, tant que la sécurité de nos équipes est assurée : abandonner les populations les plus vulnérables n’est pas une option.",
                // firstParagraph : "Médecins Sans Frontières est une organisation médicale humanitaire internationale, créée en 1971 par des médecins et des journalistes." ,
                // secondParagraph : "Avec des centaines de projets dans plus de 60 pays, nos équipes mettent tout en œuvre pour soigner les victimes de guerres, de catastrophes et d’épidémies. Telle est notre mission. Pour la réaliser, nous prenons soin d’être totalement indépendants de toute règle politique, économique, militaire ou religieuse." ,
                // thirdParagraph : "Notre financement provient à 99% de particuliers, de cette manière nous pouvons décider quelle aide nous voulons accorder. Nous sommes neutres et nous avons un seul intérêt à l’esprit : celui de nos patients."
            },
            secondCase:{
                firstParagraph : "Médecins Sans Frontières intervient depuis cinquante ans pour apporter une assistance médicale à des populations dont la vie ou la santé est menacée, en Belgique ou à l’étranger."
            },
            thirdCase:{
                firstParagraph : "Il existe dans le monde de nombreux systèmes de santé différents, offrant un accès plus ou moins rapide, gratuit et efficace aux soins de santé. En effet, certains contextes politiques, sociaux ou encore économiques ne permettent pas de répondre aux besoins de santé primaires. MSF œuvre dans de nombreux domaines, tant pour lutter contre la malnutrition, les violences sexuelles, que pour apporter une aide pédiatrique, favoriser un accès à la contraception, ou aux soins en santé mentale."
            },
            fourthCase:{
                firstParagraph : "Un pays en guerre ne peut procurer de soins médicaux primaires. Quant aux populations, elles sont encore plus vulnérables face aux maladies étant donné la précarité de leurs conditions de vie. L’intervention de MSF porte essentiellement sur l’assistance médicale des populations, la prise en charge chirurgicale des blessés de guerre, mais aussi sur l’accès aux soins primaires pour les réfugiés, contraints de quitter leur pays pour vivre dans des camps."
            },
            lastCase:{
                firstParagraph : "Voici six ans que les équipes de MSF travaillant sur les navires de recherche et de sauvetage assistent avec horreur à la tragédie humaine qui se déroule aux portes de l'Europe. MSF a affrèté son propre navire, le Geo Barents, pour sauver la vie de ces réfugiés et migrants qui tentent cette traversée mortelle de la Mer Méditerranée depuis la Libye. Depuis le début de l'année, des centaines de personnes y ont laissé leur vie. Ceux qui ne meurent pas en mer courent le risque d'être interceptés par les garde-côtes libyens - soutenus par l'UE - et d’être ensuite renvoyés de force en Libye. La plupart finissent enfermés arbitrairement dans de dangereux centres de détention où ils sont exposés à de mauvais traitements, des violences sexuelles, ou encore à de l'exploitation. "
            },
            prepaidCase:{
                firstParagraph:"Les premières heures après une catastrophe sont cruciales. Avec “Prepaid Donations”, vos dons peuvent être là aussi vite que nos équipes d’intervention. Tous vos dons sont directement liés à un fonds d’urgence qui joue un rôle crucial dans la rapidité d’intervention, car les fonds déjà disponibles nous permettent de fournir des soins immédiats. Cette nouvelle manière de faire des dons vous permet de nous rejoindre en première ligne et de pouvoir suivre en temps réel l’avancement de nos opérations sur le terrain."
            },
            footer:{
                firstTitle: "Info",
                secondTitle: "Shortcut",
                firstItem: "Contact MSF",
                firstItemLink: "https://www.msf-azg.be/fr/contacter-msf",
                secondItem: "Visit MSF.be",
                secondItemLink: "https://www.msf-azg.be/fr",
                InstaLink: "https://www.instagram.com/msfbelgique/",
                TwitterLink: "https://twitter.com/msfbelgique/",
                bancaire: "COMPTE BANCAIRE",
                iban:"IBAN: BE73 0000 0000 6060",
                bic:"BIC BPOTBEB1",
                legal: "Term of use",
                legalLink: "https://www.msf-azg.be/fr/mentions-legales",
                legal2:"Privacy",
                legal2Link:"https://www.msf-azg.be/fr/mentions-legales",
            } 
        }
      },
      nl: {
        translation: {
            main: {
                skip: "Intro overslaan",
                progress:"Scroll en ontdek wat we doen",
                siteTitle:"Artsen zonder grenzen - Prepaid Donations",
                switchLang:"FR",

            },
    
            logo:{
                alt: "Artsen zonder grenzen",
                link: "https://www.msf-azg.be/nl",
                source:"logo-nl",
            },
    
            titles: {
                firstTitle: "Verleg mee de grens<br/>tussen opgeven...",
                secondTitle: "...en hoop geven.",
                thirdTitle: "Wie zijn wij?",
                fourthTitle: "Onze interventies",
                fifthTitle:"Verleg mee de grens<br>tussen aangrijpend<br>en ingrijpen.",
                sixthTitle:"Verleg mee de grens<br> tussen opgeven<br> en hoop geven.",
                seventhTitle:"Verleg mee de grens<br> tussen te laat<br> en op tijd.",  
                eighthTitle :"Prepaid<br> Donations.",
                lastTitle : "Kies jouw<br> manier om<br> te schenken",
            },
            subtitles: {
                firstSubtitle: "Toegang tot gezondheidszorg",
                secondSubtitle: "Crisis en conflicten",
                thirdSubtitle: "Search and rescue",
                // fourthSubtitle: "Introductie van Prepaid Donations"
            },
            cta: {
                mainCta :"Doe nu een gift",
                mainCtaLink : "https://donate.msf-azg.be/b?cid=119&lang=nl_BE",
                smallCta:"READ MORE",
                smallCtaLink:"https://www.msf-azg.be/nl",
                smallCtaLinkPrepaid:"https://www.prepaiddonations.be/nl",
                firstCta :"Klassieke online gift",
                firstCtaLink :"https://donate.msf-azg.be/b?cid=119&lang=nl_BE",
                secondCta :"Prepaid Donations",
                secondCtaLink :"https://www.prepaiddonations.be/nl",
                smallCtaLinkthird :"https://www.msf-azg.be/nl/wie-zijn-wij",
                smallCtaLinktfourth :"https://www.msf-azg.be/nl/nieuws",
            },
    
            firstCase:{
                firstParagraph : "Gezondheidszorg kent geen grenzen. Toch stuiten wij elke dag op geografische, economische en politieke grenzen die ons beletten de best mogelijke zorg te verlenen. Daarom strijdt Artsen zonder Grenzen ervoor om deze grenzen te verleggen, op elke mogelijke manier. Zolang de veiligheid van onze teams gewaarborgd is, blijven wij ter plaatse. Want de meest kwetsbare bevolkingsgroepen in de steek laten, dat is geen optie." ,
                // firstParagraph : "Artsen Zonder Grenzen is een internationale medische, humanitaire organisatie met honderden projecten in meer dan 60 landen. Onze teams zetten alles op alles om slachtoffers van conflicten, natuurrampen of epidemieën te kunnen verzorgen." ,
                // secondParagraph : "We bieden medische zorg aan zij die dat het hardst nodig hebben, ongeacht hun geloof, afkomst of aan welke kant van de frontlinie zij zich ook bevinden. Daarom is het zo belangrijk dat we onafhankelijk, onpartijdig en neutraal zijn." ,
                // thirdParagraph : "Al onze medische hulpprojecten worden voor 98,5% gefinancierd door particuliere schenkers.  Daardoor kunnen we volledig zelf beslissen welke hulp we waar verlenen. En aan wie. Want we hebben steeds maar één belang voor ogen: dat van onze patiënten. "
            },
            secondCase:{
                firstParagraph : "Artsen Zonder Grenzen verleent al vijftig jaar medische hulp aan mensen in nood, in België of in het buitenland."
            },
            thirdCase:{
                firstParagraph : "In veel landen waar we werken is een brede toegang tot gezondheidszorg een probleem. Dat betekent dat patiënten maar moeilijk de zorg kunnen bereiken die ze nodig hebben, omdat die zorg bijvoorbeeld te duur, te ver of te beperkt is. Daarom bieden onze teams de meest uiteenlopende zorg steeds gratis aan én proberen we de mensen te bereiken waar zij zich bevinden. Of we nu ondervoede kinderen verzorgen, slachtoffers van gendergerelateerd geweld opvangen, hiv-patiënten behandelen of geestelijke gezondheidszorg bieden aan vluchtelingen en migranten bieden… We proberen steeds daar te zijn met de zorg die op dat moment nodig is."
            },
            fourthCase:{
                firstParagraph : "Oorlog, conflict of geweld hebben vaak een enorme impact op de gezondheidszorg van een land. In conflictgebieden draaien veel zorgvoorzieningen op beperkte kracht of zijn ze zelfs gesloten. Artsen Zonder Grenzen biedt ook in die regio’s essentiële medische zorg aan de getroffen bevolking. Dat kan gaan van chirurgie voor oorlogsgewonden, over basiszorg voor mensen op de vlucht, tot vaccinatie van kinderen of kraamzorg voor zwangere vrouwen."
            },
            lastCase:{
                firstParagraph : "Zes jaar lang hebben AZG-teams op reddingsboten met afgrijzen toegekeken hoe zich voor de poorten van Europa een menselijke tragedie ontvouwde. Artsen Zonder Grenzen heeft een eigen schip, de Geo Barents, gecharterd om levens te redden van vluchtelingen en migranten die de levensgevaarlijke oversteek van de Middellandse Zee vanuit Libië proberen te maken. Sinds het begin van het jaar zijn honderden mensen overleven. Degenen die niet op zee omkomen, lopen het risico te worden onderschept door de Libische kustwacht - die door de EU wordt gesteund - en vervolgens onder dwang naar Libië worden teruggestuurd. De meesten worden willekeurig opgesloten in gevaarlijke detentiecentra waar zij mishandeling, seksueel geweld en uitbuiting ondergaan. "
            },
            prepaidCase:{
                firstParagraph:"De eerste uren na een ramp zijn cruciaal. Met Prepaid Donations kan jouw gift er net zo snel zijn als onze responsteams. Al jouw giften zijn rechtstreeks gekoppeld aan ons noodfonds dat een cruciale rol speelt in onze reactiesnelheid. Die fondsen zijn meteen beschikbaar voor onze teams ter plaatse, zodat ze meteen de hulp kunnen bieden die nodig is. Met deze nieuwe manier van doneren, sta jij naast ons in de frontlinie. Volg de laatste updates van onze interventies in real time."
            },
            footer:{
                firstTitle: "Info",
                secondTitle: "Shortcut",
                firstItem: "Contact MSF",
                firstItemLink: "https://www.msf-azg.be/nl/contacteer-ons",
                secondItem: "Visit AZG.be",
                secondItemLink: "https://www.msf-azg.be/nl",
                InstaLink: "https://www.instagram.com/azgbelgie/",
                TwitterLink: "https://twitter.com/azgbelgie/",
                bancaire: "BANKGEGEVENS",
                iban:"IBAN: BE73 0000 0000 6060",
                bic:"BIC BPOTBEB1",
                legal: "Term of use",
                legalLink: "https://www.msf-azg.be/nl/disclaimer",
                legal2:"Privacy",
                legal2Link:"https://www.msf-azg.be/nl/disclaimer",
            }            
        }
      }
    }
  }, function(err, t) {
    updateLng()
});

document.getElementById("languageSwitch").addEventListener('click', function(evt) {
    evt.preventDefault();
    if(i18next.t("main.switchLang") == 'NL'){
        i18next.changeLanguage("nl");
        updateLng()
    }else{
        i18next.changeLanguage("fr");
        updateLng()
    }
});

function updateLng(){
    localize("title");
    localize("main");
    if(i18next.t("logo.source") == 'logo-fr'){
        document.getElementById("logo").src = logofr;
        document.getElementById("logo__footer").src = logofr;
    }else{
        document.getElementById("logo").src = logonl;
        document.getElementById("logo__footer").src = logonl;
    }
}